<script lang="ts" setup>
import { mdiArrowRight } from "@mdi/js";

const { t } = useI18n();
const localeRoute = useLocaleRoute();

const props = withDefaults(
  defineProps<{
    employee?: string;
    slug?: string;
    image?: string;
  }>(),
  {
    employee: "",
    slug: "",
    image: "",
  },
);

const link = localeRoute({
  name: "employees",
  hash: `#${props.slug}`,
});
</script>

<template>
  <div class="ga-8 d-flex flex-wrap justify-center hw-mt-smaller">
    <employees-card
      :name="employee"
      show-contact
      show-executive-role
      show-primary-team
    />

    <v-card width="100%" max-width="720px" :elevation="0">
      <hw-image
        :src="image"
        aspect-ratio="1.5"
        content-class="d-flex flex-column justify-end"
        class="h-100"
      >
        <div
          class="d-flex pa-4"
          style="
            background-color: rgba(var(--v-theme-background), 0.9);
            min-height: 96px;
          "
        >
          <hw-button
            :icon="[mdiArrowRight]"
            :to="link"
            :title="t('learn-more-about-our-team', [t(`slugs.${props.slug}`)])"
            underline
            class="ml-auto"
          />
        </div>
      </hw-image>
    </v-card>
  </div>
</template>
<i18n>
{
  "de": {
    "learn-more-about-our-team": "Mehr über unser Team {0}",
    "slugs": {
      "app-dev": "App-Entwicklung",
      "mobile-apps": "Mobile Apps",
      "dev-ops": "Dev Ops",
      "shopware": "Shopware",
      "artificial-intelligence": "Künstliche Intelligenz"
    }
  },
  "en": {
    "learn-more-about-our-team": "Learn more about our Team {0}",
    "slugs": {
      "app-dev": "App Development",
      "mobile-apps": "Mobile apps",
      "dev-ops": "Dev Ops",
      "shopware": "Shopware",
      "artificial-intelligence": "Artificial Intelligence"
    }
  }
}
</i18n>
